import React from 'react';
import Img from "gatsby-image";
import CalendlyButtonLink from "@components/book-video-call/";

const ContactSidebarTeam = (props) => {
    return(
        <>
        {props.membersData.map((item) => {
            return(
            <div key={item.id} className="module-contact-sidebar__text__card d-flex">
                <div>
                    {item.Image &&
                        <Img fixed={item.Image.childImageSharp.fixed} />
                    }
                </div>
                <div className="pl-30">
                    {item.Name && <div className="card__member__name font-weight-bold">{item.Name}</div>}
                    {item.PhoneNumber && <div className="card__member__phone"><a href={`tel:${item.PhoneNumber.replace(/\s+/g, '')}`}>{item.PhoneNumber}</a></div>}
                    {item.CalendlyLink && <div className="card__member__video__call">
                        <CalendlyButtonLink
                            label={item.BookVideoLabel}
                            url={item.CalendlyLink}
                            btnClass='btn btn-none'
                        />
                    </div>}
                </div>
            </div>
            )
        })}
        </>
    )
}

export default ContactSidebarTeam