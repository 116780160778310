import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import VideoModal from "@components/modal/video-modal-img";

export default function ContactSidebarImage(props) {

    return (
        <div className="module-contact-sidebar__image">
            {props.showVideo ? (
                <VideoModal
                    imgUrl={props.imgUrl}
                    imgClass=" "
                    showVideo={props.showVideo}
                    videoId={props.videoUrl}
                    videoChannel={props.videoChannel}
                    hideCircleIcon={true}
                />
            ) : (
                props.videoask ? (
                    <div className="videoask-frame">
                        <iframe src={props.videoaskUrl ? props.videoaskUrl : ""} title="crux careers" allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;" width="100%" height="330px" border="0"></iframe>
                    </div>
                ) : (
                    <BackgroundImage
                        Tag="div"
                        className={props.imageClass}
                        fluid={props.imgUrl}
                        loading="eager"
                        role="img"
                    />
                )
            )}
        </div>
    );
}