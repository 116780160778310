import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import { useMediaQuery } from 'react-responsive';

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import  "./assets/_index.scss";

import ContactSidebarImage from './ContactSidebarImage';
import ContactSidebarText from './ContactSidebarText';
import ContactSidebarSave from './ContactSidebarSave';
import ContactSidebarTeam from './ContactSidebarTeam';
import Buttons from "@components/modules/content-header-image/_content-sidebar-btns";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const ContactSidebar = (props) => {
    let sidebarAnim = useRef(null);
    const enableSticky = useMediaQuery({
        maxWidth: 1279
    })

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: sidebarAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none none',
                ease: animSetting.ease
            }
        });
        tl.fromTo(sidebarAnim, {
            opacity: 0,
            y: 100,
        },{
            opacity: 1,
            y: 0,
            duration: 1.5
        })
    }, [])

    return(
        <Sticky
            boundaryElement="#sidebar-end-scroll"
            bottomOffset={80}
            className={props.moduleClass}
            dontUpdateHolderHeightWhenSticky={true}
            disabled={enableSticky}
        >
            <div id="sidebar-scroll" ref={el => sidebarAnim = el}>
                <div className={`${props.sidebarData?.hideModuleBg ? 'module-contact-sidebar__inner d-flex flex-column flex-md-row flex-xl-column align-items-center' : 'module-contact-sidebar__inner module-contact-sidebar__inner__bg d-flex flex-column flex-md-row flex-xl-column align-items-center'}`}>
                    {props.sidebarData?.sidebarVideo &&
                        <ContactSidebarImage
                            imgUrl={props.sidebarData?.sidebarVideo.posterImage ? props.sidebarData?.sidebarVideo.posterImage.childImageSharp.fluid : null}
                            imageClass={props.imageClass}
                            showVideo={props.sidebarData?.sidebarVideo.showVideo}
                            videoUrl={props.sidebarData?.sidebarVideo.showVideo ? props.sidebarData?.sidebarVideo?.videoId : null}
                            videoTitle={props.sidebarData?.sidebarVideo?.videoTitle ? props.sidebarData?.sidebarVideo?.videoTitle : null}
                            videoask={props.sidebarData?.sidebarVideo.videoask ? props.sidebarData?.sidebarVideo.videoask : false}
                            videoaskUrl={props.sidebarData?.sidebarVideo.videoask ? props.sidebarData?.sidebarVideo.videoId : null}
                        />
                    }
                    <div className="module-contact-sidebar__text">
                        {props.text && props.sidebarData?.hideModuleBg &&
                            <ContactSidebarText 
                                text={props.sendusCV ? 'Interested in working with us? Contact today!' : props.sidebarData?.sidebarVideo?.videoTitle ? props.sidebarData?.sidebarVideo?.videoTitle : props.text}
                            />
                        }
                        {props.sidebarData?.sidebarButtons && !props.sidebarData?.hideModuleBg &&
                            
                            <div className="module-contact-sidebar__text__card">
                                <Buttons
                                    btnClass={props.btnClass}
                                    btnClass2={props.btnClass2}
                                    ButtonData={props.sidebarData?.sidebarButtons}
                                />
                                {!props.sidebarData?.hideSaveBtn &&
                                    <ContactSidebarSave
                                        SaveBtnClass={props.SaveBtnClass}
                                        pageLocation={props.pageLocation ? props.pageLocation : null}
                                        pageTitle={props.pageTitle ? props.pageTitle : null}
                                    />
                                }
                            </div>
                        }
                        {props.membersData && !props.sidebarData?.hideModuleBg &&
                            <ContactSidebarTeam
                                membersData={props.membersData}
                            />
                        }
                    </div>
                </div>
            </div>
        </Sticky>
    );
}

// Check all the prop types
ContactSidebar.propTypes = {
    moduleClass: PropTypes.string,
    hideModuleBg: PropTypes.bool,
    image: PropTypes.string,
    imageClass: PropTypes.string,
    videoUrl: PropTypes.string,
    videoTitle: PropTypes.string,
    btnLabel: PropTypes.string,
    btnLink: PropTypes.string,
    btnClass: PropTypes.string,
    btnLabel2: PropTypes.string,
    btnLink2: PropTypes.string,
    btnClass2: PropTypes.string,
    hideSaveBtn: PropTypes.bool,
    SaveBtnClass: PropTypes.string,
    text: PropTypes.string,
    membersData: PropTypes.array,
    videoask: PropTypes.bool,
    videoId: PropTypes.string,

};

// Specifies the default values for props:
ContactSidebar.defaultProps = {
    moduleClass: ' module-contact-sidebar col-xs-12 offset-xl-1 col-xl-3',
    hideModuleBg: false,
    image: 'young-woman.jpg',
    imageClass: 'module-contact-sidebar__image',
    videoUrl: 'url',
    videoTitle: 'Submit Your Video Application',
    btnLabel: 'Apply for this Job',
    btnLink: '/',
    btnClass: 'btn btn-primary btn-block btn-primary-no-shadow mb-28',
    btnLabel2: 'Apply for this Job',
    btnLink2: '/',
    btnClass2: 'btn btn-primary btn-block btn-primary-no-shadow mb-28',
    hideSaveBtn: false,
    SaveBtnClass: 'd-flex justify-content-center',
    text: 'Enter details for property jobs in London and the UK here: we’ll match them with our top candidates.',
    videoask: false,
    videoId: "",
    membersData: [
        {
            id: 1,
            memberImage: 'young-woman.jpg',
            Name: 'Jenny Anderson',
            PhoneNumber: '07891 294 395',
            BookVideoLabel: 'Book a Video Call'
        }
    ]
};

export default ContactSidebar
